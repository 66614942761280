<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div v-if="popupParam.mocRelatedTaskCd === 'RT00000001'" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="psiKindItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="diagramClassCd"
            label="LBLCLASSIFICATION"
            v-model="searchParam.diagramClassCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="taskTable"
      title="관련업무 목록"
      :columns="popupParam.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="documentId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn v-if="editable" label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'task-relation-docu',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
          mocRelatedTaskCd: '',
          columns: [],
        }
      },
    }
  },
  data() {
    return {
      grid: {
        data: [],
        height: '650px'
      },
      searchParam: {
        plantCd: null,
        diagramClassCd: null,
      },
      diagKindItems: [],
      docuKindItems: [],
      editable: true,
      listUrl: '',
      listDiagKindUrl: '',
      listDocuKindUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    psiKindItems() {
      let items = [
        {
          code: 'DT00000010', codeName: '동력기계',
        },
        {
          code: 'DT00000015', codeName: '장치 및 설비',
        },
        {
          code: 'DT00000020', codeName: '배관 및 개스킷',
        },
        {
          code: 'DT00000025', codeName: '안전밸브 및 파열판',
        },
      ];
      if (this.diagKindItems && this.diagKindItems.length > 0) {
        items = this.$_.concat(items, this.diagKindItems)
      }
      if (this.docuKindItems && this.docuKindItems.length > 0) {
        items = this.$_.concat(items, this.docuKindItems)
      }
      return items;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      switch(this.popupParam.mocRelatedTaskCd) { 
        case 'RT00000001': // 공정안전자료 업데이트
          this.listUrl = selectConfig.sop.moc.relationWorkDocu.case1.list.url
          this.listDiagKindUrl = selectConfig.psi.diagramkind.url;
          this.listDocuKindUrl = selectConfig.psi.documentkind.url;
          this.getDiagKind();
          this.getDocuKind();
          break;
        case 'RT00000005': // 위험성평가 결과
          this.listUrl = selectConfig.sop.moc.relationWorkDocu.case2.list.url
          break;
        case 'RT00000010': // 교육 결과
          this.listUrl = selectConfig.sop.moc.relationWorkDocu.case3.list.url
          break;
        case 'RT00000015': // 가동전 점검 결과
          this.listUrl = selectConfig.sop.moc.relationWorkDocu.case4.list.url
          break;
        case 'RT00000020': // 안전작업 허가서
          this.listUrl = selectConfig.sop.moc.relationWorkDocu.case5.list.url
          break;
        default:
          break;
      }
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getDiagKind() {
      this.$http.url = this.listDiagKindUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.diagKindItems =this.$_.map(_result.data, item => {
          return {
            codeName: item.diagramKindName, 
            code: 'DIAG_' + item.diagramClassCd,
          }
        });
      },);
    },
    getDocuKind() {
      this.$http.url = this.listDocuKindUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.docuKindItems = this.$_.map(_result.data, item => {
          return {
            codeName: item.documentKindName, 
            code: 'DOCU_' + item.documentClassCd,
          }
        });
      },);
    },
    select() {
      let selectData = this.$refs['taskTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '하나 이상 선택하세요', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
